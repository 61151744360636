// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-albums-about-js": () => import("./../../../src/pages/albums/about.js" /* webpackChunkName: "component---src-pages-albums-about-js" */),
  "component---src-pages-albums-cover-styles-js": () => import("./../../../src/pages/albums/cover-styles.js" /* webpackChunkName: "component---src-pages-albums-cover-styles-js" */),
  "component---src-pages-albums-gallery-js": () => import("./../../../src/pages/albums/gallery.js" /* webpackChunkName: "component---src-pages-albums-gallery-js" */),
  "component---src-pages-albums-how-it-works-js": () => import("./../../../src/pages/albums/how-it-works.js" /* webpackChunkName: "component---src-pages-albums-how-it-works-js" */),
  "component---src-pages-albums-pricing-js": () => import("./../../../src/pages/albums/pricing.js" /* webpackChunkName: "component---src-pages-albums-pricing-js" */),
  "component---src-pages-albums-shipping-js": () => import("./../../../src/pages/albums/shipping.js" /* webpackChunkName: "component---src-pages-albums-shipping-js" */),
  "component---src-pages-albums-swatches-js": () => import("./../../../src/pages/albums/swatches.js" /* webpackChunkName: "component---src-pages-albums-swatches-js" */),
  "component---src-pages-check-remote-js": () => import("./../../../src/pages/check-remote.js" /* webpackChunkName: "component---src-pages-check-remote-js" */),
  "component---src-pages-children-albums-about-js": () => import("./../../../src/pages/children-albums/about.js" /* webpackChunkName: "component---src-pages-children-albums-about-js" */),
  "component---src-pages-children-albums-pricing-js": () => import("./../../../src/pages/children-albums/pricing.js" /* webpackChunkName: "component---src-pages-children-albums-pricing-js" */),
  "component---src-pages-children-albums-shipping-js": () => import("./../../../src/pages/children-albums/shipping.js" /* webpackChunkName: "component---src-pages-children-albums-shipping-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-cover-debossing-custom-dies-js": () => import("./../../../src/pages/cover-debossing/custom-dies.js" /* webpackChunkName: "component---src-pages-cover-debossing-custom-dies-js" */),
  "component---src-pages-cover-debossing-metal-type-debossing-js": () => import("./../../../src/pages/cover-debossing/metal-type-debossing.js" /* webpackChunkName: "component---src-pages-cover-debossing-metal-type-debossing-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-guest-books-about-js": () => import("./../../../src/pages/guest-books/about.js" /* webpackChunkName: "component---src-pages-guest-books-about-js" */),
  "component---src-pages-guest-books-pricing-js": () => import("./../../../src/pages/guest-books/pricing.js" /* webpackChunkName: "component---src-pages-guest-books-pricing-js" */),
  "component---src-pages-guest-books-shipping-js": () => import("./../../../src/pages/guest-books/shipping.js" /* webpackChunkName: "component---src-pages-guest-books-shipping-js" */),
  "component---src-pages-guest-books-swatches-js": () => import("./../../../src/pages/guest-books/swatches.js" /* webpackChunkName: "component---src-pages-guest-books-swatches-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-uv-printing-uv-printing-js": () => import("./../../../src/pages/uv-printing/uv-printing.js" /* webpackChunkName: "component---src-pages-uv-printing-uv-printing-js" */)
}

